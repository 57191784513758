.queries-slide{
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    overflow: hidden;

}
.queries-slide div img{
   
width: 100%;
position: relative;
    height:100%;
    transition: 0.3s;
    opacity: 0.9;
    
}
.queries-slide div img:hover{
   
    transform: scale(1.1);
    opacity: 1;
        
    }
.queries-slide div{
position: relative;
overflow: hidden; 
    
}
@media   screen and (max-width:1200px) {
  .queries-slide{
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;

}
}
.queries-slide-inner{
    width: 100%;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-150%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
$primary: #e14;
$secondary: #124;
$accent: #e72;
.queries-slide-title{
    font-size: 25px;
    color: white;
}
.queries-slide-btn{
    
    margin-top: 50px;
    font-size: 15px;
    background-color: #ec1c24;
    color: white;
    padding: 10px 9px;
    cursor: pointer;
}

.contain {
    background-color: #eee;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    position: relative;
    z-index: 100000000000000;
  }
  
.form{
  width: 80%;
  display: flex;
flex-direction: column;
position: relative;
left: 50%;
transform: translateX(-50%);
}
.form div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
flex-direction: row;
  
}
input{
  background-color: white;
  padding: 8px;
  width: 100%;
  border: 0.5px solid gray;
  box-shadow: none;
  margin: 10px;
  height: 30px;
text-align: start;

}
.textarea{

}
input, label {
  display:block;
}
::placeholder{
  color: rgba(0, 0, 0, 0.74);
  font-size: 15px;
  text-align: start;
  vertical-align: text-top;
  display: flex;
  flex-wrap: wrap;
}
.contact-btn{
  background-color: #ec1c24;
  color: white;
  width: auto;
  padding: 10px 16px; 
  font-size: 25px;
  text-align: center;
  margin:10px;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
.contact-title{
margin-bottom: 20px;
  font-size: 30px;
 font-family: 'Staatliches', cursive;
  text-align: center;
  width: 100%;
  color: rgb(0, 0, 0);

}