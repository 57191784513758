
.each-grid{
    display: flex;
    justify-content: center;
    margin: 10px;
  
    align-items: center;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.151);

}
.each-grid div{
    display: flex;
    justify-content: center;

  padding-bottom: 10px;
    align-items: center;
    flex-direction: column;
text-align: center;
}
.each-grid div div img{
  width: 280px;
}
.grids{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
   position: relative;
  
 
    margin-top: 150px;
}
.each-grid-title{
    font-size: 30px;
    width: 100px;
    color: black;
}
.each-grid-text{
    font-size: 20px;
    width: 200px;
    margin-top: 20px;
    color: black;
}
.each-grid-btn{
    text-align: center;
margin-top: 30px;
font-size: 15px;
background-color: #ec1c24;
color: white;
padding: 10px 9px;
cursor: pointer;
}


.our-story-container2{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 0px;
    background-color: rgba(0, 0, 0, 0.096);
    color: black;
   
}
.our-story-right2{
    display: flex;
    justify-content: center;
    
    align-items: center;
    align-content: space-around;
    text-align: left;
    flex-direction: column;
    padding:  0px ;
    margin-top: 0px;
    color: black;
    width: 50%;

}
.our-story-left2 img{
    width: 100%;
    height: auto;
}
.our-story-left2{
    width: 50%;

}
.space-above{
    margin-top: 150px;
}
.our-story-right-text2{
    font-size:20px; 
    text-align: justify; 
    margin-top: 30px;
    color: black;
    width: 80%;
}
.our-story-right-btn{
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
    background-color: #ec1c24;
    color: white;
    padding: 10px 7px;
    cursor: pointer;
}
@media screen  and (max-width:1200px) {
    .each-grid{
        display: flex;
        justify-content: center;
        margin: 10px;
      
        align-items: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.151);
    
    }
    .each-grid div{
        display: flex;
        justify-content: center;
    
      padding-bottom: 10px;
        align-items: center;
        flex-direction: column;
    text-align: center;
    }
    .each-grid div div img{
      width: 280px;
    }
    .grids{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
       position: relative;
      
     
        margin-top: 150px;
    }
    .each-grid-title{
        font-size: 30px;
        width: 100px;
        color: black;
        margin-top: 20px;
    }
    .each-grid-text{
        font-size: 15px;
        width: 200px;
        margin-top: 20px;
        color: black;
    }
    .each-grid-btn{
        text-align: center;
    margin-top: 30px;
    font-size: 15px;
    background-color: #ec1c24;
    color: white;
    padding: 10px 9px;
    cursor: pointer;
    }
    
    
    .our-story-container2{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-top: 50px;
        background-color: rgba(0, 0, 0, 0.096);
        color: black;
        padding-bottom: 30px;
       
    }
    .reverse{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse !important;
    }
    .our-story-right2{
        display: flex;
        justify-content: center;
        
        align-items: center;
        align-content: space-around;
        text-align: left;
        flex-direction: column;
        padding:  0px ;
        margin-top: 0px;
        color: black;
        width: 90%;
    
    }
    .our-story-left2 img{
        width: 100%;
        height: auto;
    }
    .our-story-left2{
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
    
    
    }
    .space-above{
        margin-top: 150px;
    }
    .our-story-right-text2{
        font-size:15px; 
        text-align: justify; 
        margin-top: 30px;
        color: black;
        width: 80%;
    }
    .our-story-right-btn{
        text-align: left;
        margin-top: 30px;
        font-size: 15px;
        background-color: #ec1c24;
        color: white;
        padding: 10px 7px;
        cursor: pointer;
    } 
}