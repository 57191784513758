@font-face {
 
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Jockey+One&family=Mate+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@font-face {
  font-family: Text;
  src: url(../../Proxima\ Nova\ Font.otf);

}
@font-face {
  font-family: Heading;
  src: url(../../NHaasGroteskTXPro-75Bd.ttf);
}
*{
  font-family: Text;
    /* letter-spacing: 1.5px; */
    /* line-height: 29px; */

    line-height: 1.2em
   
}
.home{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}
.our-story-title{
    margin-top: 150px;
    font-size: 50px;
   font-family: 'Staatliches', cursive;
    text-align: center;
    width: 100%;
    color: rgb(0, 0, 0);

}
.our-story-right-heading{
  font-size:40px;  
  color: black;
 font-family: 'Staatliches', cursive;
}
.what-do-we-do-title{
    margin-top: 150px;
    font-size: 40px;
    text-align: center;
    width: 100%;
    color: black;
    font-weight: 800;
}
.our-story-container{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    width: 80%;
    margin-top: 50px;
}
.our-story-left img{
    width: 450px;
    height: auto;
}
.our-story-right{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 30px;
    margin-top: 30px;
    color: black;

}

.our-story-right-text{
    font-size:20px; 
    text-align: justify; 
    margin-top: 30px;
    color: black;
}
.our-story-right-btn{
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
    background-color: #ec1c24;
    color: white;
    padding: 10px 9px;
    cursor: pointer;
}
.what-do-we-do{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-top: 80px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    width: 80%;
   
}
.what-do-we-do img{
   width: 300px;
   height: auto;
   transition: 0.2s;
}
.what-do-we-do img:hover{
    transform: translate(0, -20px);
 }
#posts {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
margin-top: 80px;

  }
  #posts div{
  
    margin: 5px;
    background-color: #0000004f;
  }
  .each-poster div{
    font-size: 20px;
    margin-top: 20px;
    padding: 10px 7px;
color: rgb(255, 255, 255);

 
  }
  .each-poster div img{
    width: 50px;
    height: auto;
    margin-top: 10px;
  }
.world-social{
  position: relative;
  margin-top: 80px;
}
.world-social img{
  position: relative;
}
.posters-body{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
  .read-watch-listen{
    text-align: center;
  }
  .read-watch-listen-title{
    margin-top: 150px;
    font-size: 50px;
    color: black;
  }
  .read-watch-listen-body{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
  }
  .read-watch-listen-body div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }
  .read-watch-listen-body div div img{
    width: 400px;
    height: 300px;
    transition: 0.2s; 
  }
  .read-watch-listen-body div div img:hover{
    transform: translate(0, -20px);
  }
  .read-watch-listen-body div div{
    font-size: 30px;
    color: black;
  }
  .marquee img{
    width: 150px;
    margin: 0 30px;
    height: auto;
  }
  .marquee{
    width: 100%;
  }
.home{

}
.new-events-box{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.151);
    padding-bottom: 40px;
    margin-top: 40px;
}
.new-events-box div {
  margin-top: 60px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    margin-left: 30px;

}
.new-events-box div img{
    width: 400px;
    height: auto;

}
.new-events-box-text{
    color: rgb(0, 0, 0);
    font-size: 30px;
    width: 300px;
    display: flex;
    flex-wrap: wrap;
}
.new-events-box-btn{
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
    background-color: #ec1c24;
    color: white;
    padding: 10px 7px;
    cursor: pointer;
}
.events{
    margin-top: 150px;
    font-size: 50px;
    text-align: center;
    width: 100%;
    color: black;
}











@media screen  and (max-width:1200px){
  .our-story-title{
    margin-top: 150px;
    font-size: 50px;
    text-align: center;
    width: 100%;
    color: rgb(0, 0, 0);

}
.what-do-we-do-title{
    margin-top: 150px;
    font-size: 50px;
    text-align: center;
    width: 100%;
    color: black;
}
.our-story-container{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin-top: 50px;
}
.our-story-left img{
    width: 200px;
    height: auto;
}
.our-story-right{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 0 0 0 0px;
    margin-top: 30px;
    color: black;
    

}
.our-story-right-heading{
    font-size:40px;  
    color: black;
}
.our-story-right-text{
    font-size:25px; 
    text-align: justify; 
    margin-top: 30px;
    color: black;
}
.our-story-right-btn{
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
    background-color: #ec1c24;
    color: white;
    padding: 10px 7px;
    cursor: pointer;
}
.what-do-we-do{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-top: 80px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 80%;
    

}
.what-do-we-do img{
   width: 200px;
   height: auto;
   transition: 0.2s;
   margin-top: 50px;
}
.what-do-we-do img:hover{
    transform: translate(0, -20px);
 }
 #posts {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
margin-top: 80px;

  }
  #posts div{

    
    background-color: #0000004f;
  }
  .each-poster div{
   
    font-size: 20px;
    margin-top: 10px;
    padding: 10px 10px;
color: white;

 
  }
  .each-poster div img{
    width: 50px;
    height: auto;
    margin-top: 10px;
  }
  .world-social{
    position: relative;
    margin-top: 80px;
  }
  .world-social img{
    position: relative;
  
  }
  .world-social-img{
    display: none;
  }
  .posters-body{
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(-0%,-0%);
  }
.below{
  position: relative;
  top: -50px;
}
  .read-watch-listen{
    text-align: center;
  }
  .read-watch-listen-title{
    margin-top: 150px;
    font-size: 40px;
    color: black;
  }
  .read-watch-listen-body{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .read-watch-listen-body div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }
  .read-watch-listen-body div div img{
    width: 200px;
    height: 300px;
    transition: 0.2s; 
  }
  .read-watch-listen-body div div img:hover{
    transform: translate(0, -20px);
  }
  .read-watch-listen-body div div{
    font-size: 30px;
    color: black;
  }
  .marquee img{
    width: 150px;
    margin: 0 30px;
    height: auto;
  }
  .marquee{
    width: 100%;
  }
.home{

}
.new-events-box{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.568);
    padding-bottom: 40px;
}
.new-events-box div {
  margin-top: 60px;
  margin-left: 0 !important;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
 

}
.new-events-box div img{
    width: 80%;
    height: auto;

}
.new-events-box-text{
    color: rgb(255, 255, 255);
    font-size: 30px;
 
    display: flex;
    flex-wrap: wrap;
 
}
.new-events-box-btn{
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
    background-color: #ec1c24;
    color: white;
    padding: 10px 7px;
    cursor: pointer;
}
.events{
    margin-top: 150px;
    font-size: 50px;
    text-align: center;
    width: 100%;
    color: black;
}
}